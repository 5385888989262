import React from 'react';

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-48 mb-8 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Over ons</h2>
        <p className="mt-5">Deceulener Interieur</p>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Voorwaarden</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="/privacypolicy">Privacy Policy</a>
          </li>
          <li>
            <a href="/termsandconditions">Terms &amp; Conditions</a>
          </li>
        </ul>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Links</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://deceulener.be/">Deceulener Interieur</a>
          </li>
          <li>
            <a href="https://www.mighty-real.com/">Website Design by : Mighty Real ICT & Media Solutions</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
